import {
  getCategoriesByIndexes,
  listCategories,
} from "@/api/routes/category-bank"
import { IQuestionCategory } from "@/api/types/question/question"
import { PageLoader } from "@/components/PageLoader"
import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import { useDisclosure } from "@nextui-org/react"
import { useEffect, useState } from "react"
import "./index.css"
import { faFolder } from "@fortawesome/free-solid-svg-icons"
import LargeButtonWidget from "@/components/Widgets/LargeButtonWidget"
import NotesBrowser from "./NotesBrowser"
import { parse } from "postcss"
import { PageData } from "@/api/types/general"
import { SidebarLink } from "@/components/Sidebar"
import SideBarNotesBrowser from "./components/SideBarNotesBrowser"

export default function LearningPage() {
  const [categories, setCategories] = useState<IQuestionCategory[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const viewNotesDisclosure = useDisclosure()
  const viewVideoDisclosure = useDisclosure()
  const [selectedNoteId, setSelectedNoteId] = useState<string | undefined>()
  const [selectedVideoId, setSelectedVideoId] = useState<string | undefined>()

  const [selectedDirectoriesNames, setSelectedDirectoriesNames] = useState<
    string[]
  >([])
  const [selectedDirectoriesIds, setSelectedDirectoriesIds] = useState<
    string[]
  >([])
  const [selectedDirectoriesPath, setSelectedDirectoriesPath] = useState<
    string[]
  >([])
  const [hasLoadedPaths, setHasLoadedPaths] = useState<boolean>(false)

  const loadCategories = async () => {
    const categories = await listCategories(undefined, 500, {
      page: 1,
      limit: 500,
      fetchMeta: false,
    } as PageData)
    if (categories) {
      setCategories(categories)
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await loadCategories()
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (!hasLoadedPaths) {
      setHasLoadedPaths(true)
      return
    }
    // Push to route
    const path = selectedDirectoriesPath.join("/")

    // Check if route is already set
    if (window.location.pathname === path) {
      return
    }

    console.log("Setting path to ")
    console.log(path)

    // Push to history
    window.history.pushState({}, "", `/Learning/${path}`)
  }, [selectedDirectoriesPath])

  // On load handler to deal with setting page to path
  useEffect(() => {
    console.log("Running load handler")
    ;(async () => {
      const path = window.location.pathname
        .split("/")
        .slice(2)
        .filter((x) => x !== "")
      if (path.length === 0) {
        return
      }

      setSelectedDirectoriesPath(path)

      // Loop through path and set selected directories and ids
      let dirPath = [...path]

      for (let i = 0; i < path.length; i++) {
        if (path.length < i + 1) {
          break
        }
      }

      const categoryOrSubCategory = await getCategoriesByIndexes(
        dirPath.map((x) => parseInt(x)),
      )

      setCategories(categoryOrSubCategory.subCategories)
      setSelectedDirectoriesIds(categoryOrSubCategory.idTrail)
      setSelectedDirectoriesNames(categoryOrSubCategory.nameTrail)
    })()
  }, [])

  return (
    <Page
      showSidebar={true}
      // sidebarContentOverride={categories.map((category, index: number) => (
      //   <SidebarLink
      //     key={category._id}
      //     icon={faFolder}
      //     title={category.name}
      //     isSelectedOverride={selectedDirectoriesIds?.includes(
      //       category?._id ?? "n/a",
      //     )}
      //     onClick={() => {
      //       setSelectedDirectoriesNames((prev) => [category.name])
      //       setSelectedDirectoriesIds((prev) => [category._id!])
      //       setSelectedDirectoriesPath((prev) => [index.toString()])
      //     }}
      //   />
      // ))}
      sidebarContentOverride={
        <SideBarNotesBrowser
          setCategories={setCategories}
          categories={categories}
          isRootCategory={true}
          setSelectedDirectoriesNames={setSelectedDirectoriesNames}
          setSelectedDirectoriesIds={setSelectedDirectoriesIds}
          setSelectedDirectoriesPath={setSelectedDirectoriesPath}
          selectedDirectoriesIds={selectedDirectoriesIds}
          selectedDirectoriesPath={selectedDirectoriesPath}
          selectedDirectoriesNames={selectedDirectoriesNames}
        />
      }
    >
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <PageHeader>
            <h1>Learning</h1>
          </PageHeader>

          {selectedDirectoriesIds.length === 0 ? (
            <div className="notes-browser-grid">
              {categories.map((category, index: number) => (
                <LargeButtonWidget
                  key={category._id}
                  icon={faFolder}
                  title={category.name}
                  variant="faded"
                  color="default"
                  onClick={() => {
                    setSelectedDirectoriesNames((prev) => [
                      ...prev,
                      category.name,
                    ])
                    setSelectedDirectoriesIds((prev) => [
                      ...prev,
                      category._id!,
                    ])
                    setSelectedDirectoriesPath((prev) => [
                      ...prev,
                      index.toString(),
                    ])
                  }}
                />
              ))}
            </div>
          ) : (
            <NotesBrowser
              selectedDirectoriesIds={selectedDirectoriesIds}
              selectedDirectoriesNames={selectedDirectoriesNames}
              selectedDirectoriesPath={selectedDirectoriesPath}
              setSelectedDirectoriesIds={setSelectedDirectoriesIds}
              setSelectedDirectoriesNames={setSelectedDirectoriesNames}
              setSelectedDirectoriesPath={setSelectedDirectoriesPath}
              viewNotesDisclosure={viewNotesDisclosure}
              setSelectedNoteId={setSelectedNoteId}
              viewVideoDisclosure={viewVideoDisclosure}
              setSelectedVideoId={setSelectedVideoId}
            />
          )}
        </>
      )}
    </Page>
  )
}
