import { listCategories } from "@/api/routes/category-bank"
import {
  IQuestionBankQuestion,
  IQuestionCategory,
} from "@/api/types/question/question"
import { PageLoader } from "@/components/PageLoader"
import VideoWidget from "@/components/Widgets/VideoWidget"
import Page from "@/core/components/Page"
import PageHeader from "@/core/components/Page/PageHeader"
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  Checkbox,
  Chip,
  Spacer,
  Spinner,
  Tab,
  Tabs,
} from "@nextui-org/react"
import { useEffect, useState } from "react"
import "./index.css"
import CategorySelectCard from "./components/CategorySelectCard"
import InlineQuestionCard from "./components/InlineQuestionCard"
import { listQuestions } from "@/api/routes/user-question-quiz"
import InfiniteScroll from "react-infinite-scroll-component"
import StartQuestionsCard from "./components/StartQuestionsCard"
import {
  getCategoryStatistics,
  getQuestionPracticeCategoryStatistics,
} from "@/api/routes/statistics"
import { useAppSelector } from "@/app/hooks"

interface SelectedCategories {
  [key: string]: boolean
}

export interface IQuestionBankQuestionWithSelectedProps
  extends IQuestionBankQuestion {
  isSelected: boolean
}

export enum QuestionPracticeTab {
  ALL = "all",
  NOT_FULL_MARKS = "not-full-marks",
  FULL_MARKS = "full-marks",
}

export default function QuestionsPage() {
  const [categories, setCategories] = useState<IQuestionCategory[]>([])
  const [questions, setQuestions] = useState<
    IQuestionBankQuestionWithSelectedProps[]
  >([])
  const [loading, setLoading] = useState<boolean>(true)
  const [questionsLoading, setQuestionsLoading] = useState<boolean>(true)
  const [scrollLoading, setScrollLoading] = useState<boolean>(false)
  const [isSelected, setIsSelected] = useState<SelectedCategories>({})
  const [questionPracticeTab, setQuestionPracticeTab] =
    useState<QuestionPracticeTab>(QuestionPracticeTab.ALL)
  const [categoryStats, setCategoryStats] = useState<any>({})

  // Pagination
  const [totalPages, setTotalPages] = useState(0)
  const [pageLimit, setPageLimit] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)

  const currentUser = useAppSelector((state) => state.user.user)

  const doesUserHaveSubscription =
    currentUser?.subscriptionData["unlimited-interviews"]?.value === true ||
    currentUser?.role === "admin"

  // Load statistics
  const loadStatistics = async () => {
    const categoryStatistics = await getQuestionPracticeCategoryStatistics()

    if (!categoryStatistics || Object.keys(categoryStatistics).length === 0) {
      setCategoryStats({
        all: {
          percentAnsweredCorrect: 0,
          percentAnsweredCorrectNoDp: 0,
          percentAnsweredIncorrect: 0,
        },
      })
      return
    }

    // Calculate our percentages
    for (let categoryKey of Object.keys(categoryStatistics)) {
      let category = categoryStatistics[categoryKey]

      // Space for green/red bars = totalQuestionsAnswered / totalQuestions
      const totalColouredSpace =
        (category.totalQuestionsAnswered / category.totalQuestions) * 100

      // limit to 3dp
      category.percentAnsweredCorrect =
        category.questionsScoreScaledAverage * totalColouredSpace
      category.percentAnsweredIncorrect =
        totalColouredSpace - category.percentAnsweredCorrect

      category.percentAnsweredCorrect =
        Math.round(category.percentAnsweredCorrect * 1000) / 1000
      category.percentAnsweredCorrectNoDp = Math.round(
        category.percentAnsweredCorrect,
      )
      category.percentAnsweredIncorrect =
        Math.round(category.percentAnsweredIncorrect * 1000) / 1000

      if (isNaN(category.percentAnsweredCorrect)) {
        category.percentAnsweredCorrect = 0
      }
      if (isNaN(category.percentAnsweredIncorrect)) {
        category.percentAnsweredIncorrect = 0
      }
      if (isNaN(category.percentAnsweredCorrectNoDp)) {
        category.percentAnsweredCorrectNoDp = 0
      }
    }

    setCategoryStats(categoryStatistics)
  }

  const loadCategories = async () => {
    const categories = await listCategories()
    if (categories) {
      setCategories(categories)
    }
  }

  const loadQuestions = async (page: number = 1) => {
    const totalRecords = await listQuestions(
      getSelectedCategoryIds(),
      pageLimit,
      {
        fetchMeta: true,
      },
      {
        questionPracticeTab: questionPracticeTab, // TODO: CHANGE
      },
    )
    if (totalRecords) {
      setTotalPages(totalRecords)
    }

    const questions = (
      await listQuestions(
        getSelectedCategoryIds(),
        pageLimit,
        {
          page,
          limit: pageLimit,
        },
        {
          questionPracticeTab: questionPracticeTab, // TODO: CHANGE
        },
      )
    )?.map((question: IQuestionBankQuestion) => ({
      ...question,
      isSelected: false,
    }))

    if (page === 1) {
      setQuestions(questions)
    } else {
      setQuestions((prev) => [...prev, ...questions])
    }
  }

  const getSelectedCategoryIds = (): string[] => {
    return categories
      .filter((category) => isSelected[category._id!])
      .map((category) => category._id ?? "<ERROR>")
  }

  const areAllCategoriesSelected = () => {
    let allSelected = true

    for (let category of categories) {
      if (!isSelected[category._id!]) {
        allSelected = false
        break
      }
    }

    return allSelected
  }

  const toggleSelectAll = () => {
    const allSelected = areAllCategoriesSelected()
    if (allSelected) {
      setIsSelected({})
    } else {
      setIsSelected(
        categories.reduce((acc, category) => {
          acc[category._id!] = true
          return acc
        }, {} as SelectedCategories),
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await loadStatistics()
      await loadCategories()
      await loadQuestions()
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setQuestionsLoading(true)
      await loadQuestions()
      setQuestionsLoading(false)
    })()
  }, [isSelected, questionPracticeTab])

  return (
    <Page showSidebar={true}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <PageHeader>
            <div>
              <h1>Question Practice </h1>
              <p>Practice unlimited questions from areas of focus.</p>
            </div>
          </PageHeader>
          <div className="widget-grid no-wrap full-height questions-page-grid">
            <Card className="category-select-widget" shadow="none">
              <Accordion
                variant="bordered"
                defaultExpandedKeys={["1", "2"]}
                selectionMode="multiple"
              >
                <AccordionItem
                  key="1"
                  subtitle="Filter questions by previous performance"
                  title="Performance"
                >
                  <Tabs
                    variant="bordered"
                    isVertical={true}
                    fullWidth={true}
                    selectedKey={questionPracticeTab}
                    onSelectionChange={(key) => {
                      setQuestionPracticeTab(key as QuestionPracticeTab)
                    }}
                  >
                    <Tab key="all" title="All"></Tab>
                    <Tab
                      key="not-full-marks"
                      title="Not Scored Full Marks"
                    ></Tab>
                    <Tab key="full-marks" title="Scored Full Marks"></Tab>
                  </Tabs>
                </AccordionItem>
                <AccordionItem
                  key="2"
                  subtitle="Filter questions by category"
                  title="Categories"
                >
                  <div className="questions-category-filter">
                    <CategorySelectCard
                      isSelected={areAllCategoriesSelected()}
                      setIsSelected={toggleSelectAll}
                      greenProgress={
                        categoryStats["all"].percentAnsweredCorrect
                      }
                      redProgress={
                        categoryStats["all"].percentAnsweredIncorrect
                      }
                      categoryName={"Select All"}
                      bold={true}
                      score={`${
                        // Round to 2dp
                        categoryStats["all"].percentAnsweredCorrectNoDp
                      }%`}
                    />
                    {categories.map((category) => (
                      <>
                        <CategorySelectCard
                          isSelected={isSelected[category._id!] || false}
                          setIsSelected={(isSelected) => {
                            setIsSelected((prev) => ({
                              ...prev,
                              [category._id!]: isSelected,
                            }))
                          }}
                          greenProgress={
                            categoryStats[category.name].percentAnsweredCorrect
                          }
                          redProgress={
                            categoryStats[category.name]
                              .percentAnsweredIncorrect
                          }
                          categoryName={category.name}
                          score={`${
                            // Round to 2dp
                            categoryStats[category.name]
                              .percentAnsweredCorrectNoDp
                          }%`}
                        />
                      </>
                    ))}
                  </div>
                </AccordionItem>
              </Accordion>
            </Card>

            <Card className="questions-widget" shadow="none">
              {questionsLoading && <PageLoader />}
              {!questionsLoading && (
                <>
                  {questions.length > 0 && (
                    <StartQuestionsCard
                      questions={questions}
                      questionPracticeTab={questionPracticeTab}
                      disabled={questions.length === 0}
                    />
                  )}

                  <div
                    id="scrollableDiv"
                    className="questions-widget-scrollable"
                  >
                    <InfiniteScroll
                      dataLength={totalPages * pageLimit}
                      next={async () => {
                        setScrollLoading(true)
                        await loadQuestions(currentPage + 1)
                        setCurrentPage(currentPage + 1)
                        setScrollLoading(false)
                      }}
                      hasMore={currentPage < totalPages}
                      loader={
                        scrollLoading ? (
                          <>
                            <Spacer y={2} />
                            <Spinner />
                            <Spacer y={2} />
                          </>
                        ) : null
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      {questions.map((question, index) => (
                        <>
                          <InlineQuestionCard
                            question={question}
                            isSelected={question.isSelected}
                            setIsSelected={(isSelected) => {
                              setQuestions((prev) => {
                                let next = [...prev]
                                next[index].isSelected = isSelected
                                return next
                              })
                            }}
                          />
                          <Spacer y={2} />
                        </>
                      ))}
                    </InfiniteScroll>
                  </div>
                </>
              )}
            </Card>
          </div>
        </>
      )}
    </Page>
  )
}
