import { LoginEnum } from "@/api/types/auth/auth-enums"
import { PageData } from "@/api/types/general"
import axios from "@/core/helpers/axios"

export async function getNoteById(noteId: string) {
  try {
    const res = await axios.post("/question-category/notes/get/" + noteId)

    if (res.status === 200) {
      return res.data.note
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function getNoteByIdAndPath(noteId: string, path: string[]) {
  try {
    const res = await axios.post("/question-category/notes/get/" + noteId, {
      path,
    })

    if (res.status === 200) {
      return res.data.note
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
