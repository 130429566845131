import { useNavigate, useParams } from "react-router"
import Page from "src/core/components/Page"
import {
  currentQuestion,
  getQuizMetaDataFromUserQuizId,
  answerQuestion,
  resetTimerOnCurrentQuestion,
  nextQuestion,
  finishQuiz,
  getSpecificQuestion,
} from "src/api/routes/quiz/quiz"
import { useEffect, useRef, useState } from "react"
import { PageLoader } from "src/components/PageLoader"
import { Button, Spacer, useDisclosure } from "@nextui-org/react"
import "regenerator-runtime/runtime"
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition"
import PageHeader from "src/core/components/Page/PageHeader"
import { IInProgressQuizQuestion } from "src/api/types/question/question"
import "./index.css"
import { AttemptTypeEnum, IQuizBank } from "src/api/types/quiz/quiz-bank"
import QuestionCard from "@/pages/QuizAttempt/components/QuestionCard"
import TimerCard from "@/pages/QuizAttempt/components/TimerCard"
import TotalMarksCard from "@/pages/QuizAttempt/components/TotalMarksCard"
import QuestionCursor from "@/pages/QuizAttempt/components/QuestionCursor"
import "../index.css"
import ReviewQuizPage from "../Review"
import BaseModal from "@/core/components/Modals/BaseModal"
import InstructionsDisplay from "../components/InstructionsDisplay"
import QuestionAnswerInput from "../components/QuestionAnswerInput"
import CountdownTimerCard from "../components/CountdownTimerCard"
import {
  deleteVoiceFile,
  uploadVoice,
  viewVoiceFile,
} from "@/api/routes/voice/voice"
import { StartAiChat } from "@/api/routes/ai-chat"
import AiChatbox from "@/components/AiChatbox"

interface Props {
  isActorMode?: boolean
}

export default function IntervieweeInProgressQuizPage(props: Props) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [question, setQuestion] = useState(
    null as IInProgressQuizQuestion | null,
  )
  const [questionContent, setQuestionContent] = useState("" as string)
  const [totalSeconds, setTotalSeconds] = useState(0)
  const [quizData, setQuizData] = useState(null as IQuizBank | null)
  const [answer, setAnswer] = useState("" as string)
  const [preLoadedVoiceBlob, setPreLoadedVoiceBlob] = useState(
    null as Blob | null,
  )
  const [voiceBlob, setVoiceBlob] = useState(null as Blob | null)
  const [isActorMode, setIsActorMode] = useState<boolean>(
    props.isActorMode ?? false,
  )
  const { quizId } = useParams()

  const [isSavingVoice, setIsSavingVoice] = useState(false)
  const [voiceRecordingId, setVoiceRecordingId] = useState<string | null>(null)

  const [chatSessionId, setChatSessionId] = useState<string | null>(null)

  const loadQuizData = async () => {
    const quizData = await getQuizMetaDataFromUserQuizId(quizId!)
    if (quizData && quizData.attemptType === AttemptTypeEnum.MOCK_QUIZ) {
      if (quizData.endDate) {
        navigate("/Quiz/Candidate/" + quizId + "/Complete")
      } else {
        setQuizData(quizData)
      }
    } else {
      // TODO: Show error
    }
  }

  useEffect(() => {
    setVoiceBlob(preLoadedVoiceBlob)
  }, [preLoadedVoiceBlob])

  const handleVoiceUpload = async () => {
    if (voiceBlob === preLoadedVoiceBlob) return

    // If we have a voice blob, upload it
    if (voiceBlob) {
      setIsSavingVoice(true)

      if (voiceRecordingId) {
        // Delete the old voice recording
        await deleteVoiceFile(voiceRecordingId)
      }

      const voiceFile = new File([voiceBlob], "voice-upload")
      const recordingId = await uploadVoice(voiceFile)
      setVoiceRecordingId(recordingId)

      await answerQuestion(quizId!, {
        answer,
        voiceRecordingId: recordingId,
        totalTime: totalSeconds,
        questionNumber: question!.currentQuestionNumber,
      })

      setIsSavingVoice(false)
    }
  }

  useEffect(() => {
    handleVoiceUpload()
  }, [voiceBlob])

  const saveProgress = async () => {
    await answerQuestion(quizId!, {
      answer,
      voiceRecordingId,
      totalTime: totalSeconds,
      questionNumber: question!.currentQuestionNumber,
    })
  }

  const saveNonVoiceQuestionAnswer = async () => {
    if (!voiceRecordingId) {
      // If the user hasn't recorded their voice, save the answer at this stage.
      await saveProgress()
    }
  }

  const loadNextQuestion = async () => {
    // Get our next question
    if (quizData?.attemptType === AttemptTypeEnum.QUESTION_PRACTICE) {
      const newQuestionNumber = question!.currentQuestionNumber + 1

      await loadQuestionOnPage(newQuestionNumber)
    } else {
      await nextQuestion(quizId!)

      // Load our next question
      await loadQuestionOnPage()
    }
  }

  const loadPreviousQuestion = async () => {
    // Get our previous question
    const newQuestionNumber = question!.currentQuestionNumber - 1
    await loadQuestionOnPage(newQuestionNumber)
  }

  const loadQuestionOnPage = async (questionNumber: number | null = null) => {
    setLoading(true)

    // Save our current answer
    await saveNonVoiceQuestionAnswer()

    setVoiceBlob(null)
    setVoiceRecordingId(null)
    setPreLoadedVoiceBlob(null)

    // Reset our answer input
    setAnswer("")

    // Get our previous question
    await getQuestion(questionNumber)

    setLoading(false)
  }

  const loadFinishQuiz = async () => {
    setLoading(true)

    // Set our answer
    await answerQuestion(quizId!, {
      answer,
      totalTime: totalSeconds,
      questionNumber: question!.currentQuestionNumber,
    })

    setVoiceBlob(null)
    setVoiceRecordingId(null)
    setPreLoadedVoiceBlob(null)

    await finishQuiz(quizId!)

    if (quizData?.attemptType === AttemptTypeEnum.MOCK_QUIZ) {
      navigate("/Quiz/Candidate/" + quizId + "/Complete")
    } else {
      navigate("/QuestionPractice/Candidate/" + quizId + "/Complete")
    }
    setLoading(false)
  }

  const getQuestion = async (questionNumber: number | null = null) => {
    setVoiceBlob(null)
    setVoiceRecordingId(null)
    setPreLoadedVoiceBlob(null)

    let question: IInProgressQuizQuestion | undefined

    if (questionNumber) {
      question = await getSpecificQuestion(quizId!, questionNumber)
    } else {
      question = await currentQuestion(quizId!, true)
    }

    if (question) {
      setQuestion(question)
      setQuestionContent(question.question)

      // Chat session stuff
      if (question.userAnswer) {
        if (question.isAiChatBotQuestion) {
          let currentSession =
            question.userAnswer.aiChatbotSessionIds &&
            question.userAnswer.aiChatbotSessionIds.length > 0
              ? question.userAnswer.aiChatbotSessionIds[
                  question.userAnswer.aiChatbotSessionIds.length - 1
                ]
              : null

          if (currentSession === null) {
            // Start a new session
            currentSession = await StartAiChat(quizId!, question._id)
          }

          setChatSessionId(currentSession)
        }

        if (question.userAnswer.voiceRecordingId) {
          setVoiceRecordingId(question.userAnswer.voiceRecordingId)

          const voiceRecording = await viewVoiceFile(
            question.userAnswer.voiceRecordingId,
          )

          if (voiceRecording) {
            setPreLoadedVoiceBlob(voiceRecording)
          }
        }

        if (question.userAnswer.answer) {
          setAnswer(question.userAnswer.answer)
        }
      }
    } else {
      // TODO: Show error
    }
  }

  const resetTimer = async () => {
    await resetTimerOnCurrentQuestion(quizId!)
    if (question!.userAnswer) {
      question!.userAnswer.totalTime = 0
    }
    setTotalSeconds(0)
  }

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition()

  useEffect(() => {
    setAnswer(transcript)
  }, [transcript])

  useEffect(() => {
    if (quizId === "Complete") {
      setLoading(false)
    } else {
      ;(async () => {
        setLoading(true)
        await getQuestion()
        await loadQuizData() // Depends on getting the actual quiz id from the question
        setLoading(false)
      })()
    }
  }, [])

  const markModalDisclosure = useDisclosure()
  const timerRef: any = useRef()
  const onSaveMarks = async () => {
    markModalDisclosure.onClose()

    // if we can go to the next question, do that, otherwise finish the quiz
    if (question!.currentQuestionNumber < question!.totalQuestions) {
      await loadNextQuestion()
    } else {
      await loadFinishQuiz()
    }
  }

  markModalDisclosure.onOpenChange = () => {
    timerRef.current.resume()
  }

  return (
    <>
      <BaseModal
        size="full"
        isOpen={markModalDisclosure.isOpen}
        onClose={markModalDisclosure.onClose}
        onOpenChange={markModalDisclosure.onOpenChange}
        className={"light-background"}
      >
        <ReviewQuizPage
          asMarker={true}
          hideSidebar={true}
          offsetSeconds={totalSeconds}
          asWidget={true}
          answerWrittenOverride={answer}
          saveMarksExt={onSaveMarks}
          questionNumberOverride={
            quizData?.attemptType === AttemptTypeEnum.QUESTION_PRACTICE
              ? question!.currentQuestionNumber
              : undefined
          }
        />
      </BaseModal>
      <Page showSidebar={true}>
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <PageHeader>
              <h1>{quizData?.name ?? ""}</h1>
            </PageHeader>
            <div className="page-halves">
              <div className="page-left-half w-full">
                {/* Question card */}
                <QuestionCard
                  question={questionContent}
                  name={question!.name}
                />

                <InstructionsDisplay
                  quizData={quizData}
                  question={question}
                  isActorMode={isActorMode}
                />

                {/* Answer card */}
                {!isActorMode && !question!.isAiChatBotQuestion ? (
                  <QuestionAnswerInput
                    isSavingVoice={isSavingVoice}
                    answer={answer}
                    setAnswer={setAnswer}
                    setVoiceBlob={setVoiceBlob}
                    recordedBlob={voiceBlob}
                  />
                ) : null}

                {/* AI Virtual Patient question answer card */}
                {question!.isAiChatBotQuestion && chatSessionId && (
                  <AiChatbox sessionId={chatSessionId} />
                )}
              </div>
              <div className="page-right-half">
                <CountdownTimerCard
                  ref={timerRef}
                  key={question!.id + "_Timer"}
                  hidePause={false}
                  onReset={resetTimer}
                  autostart={true}
                  startTimeTaken={question!.userAnswer?.totalTime}
                  setTotalSecondsObs={setTotalSeconds}
                  expectedTime={question!.timeLimit}
                />
                <Spacer y={5} />
                <TotalMarksCard totalMarks={question!.marks} />
                <Spacer y={5} />
                <Button
                  variant="solid"
                  color="primary"
                  fullWidth={true}
                  onClick={async () => {
                    timerRef.current.pause()
                    await saveProgress()
                    markModalDisclosure.onOpen()
                  }}
                >
                  Mark Question
                </Button>
                <Spacer y={5} />
                <QuestionCursor
                  finishQuiz={
                    quizData?.attemptType === AttemptTypeEnum.QUESTION_PRACTICE
                      ? undefined
                      : loadFinishQuiz
                  }
                  prevQuestion={loadPreviousQuestion}
                  nextQuestion={loadNextQuestion}
                  currentQuestion={question!.currentQuestionNumber}
                  totalQuestions={question!.totalQuestions}
                />
              </div>
            </div>
          </>
        )}
      </Page>
    </>
  )
}
