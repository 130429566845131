import { getCategoriesByPath } from "@/api/routes/category-bank"
import { ISubQuestionCategory } from "@/api/types/question/question"
import LargeButtonWidget from "@/components/Widgets/LargeButtonWidget"
import {
  faFile,
  faFolder,
  faHome,
  faHomeAlt,
  faLayerGroup,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  BreadcrumbItem,
  Breadcrumbs,
  Spacer,
  useDisclosure,
} from "@nextui-org/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"

interface Props {
  selectedDirectoriesNames: string[]
  setSelectedDirectoriesNames: React.Dispatch<React.SetStateAction<string[]>>
  selectedDirectoriesIds: string[]
  setSelectedDirectoriesIds: React.Dispatch<React.SetStateAction<string[]>>
  selectedDirectoriesPath: string[]
  setSelectedDirectoriesPath: React.Dispatch<React.SetStateAction<string[]>>

  viewNotesDisclosure: ReturnType<typeof useDisclosure>
  viewVideoDisclosure: ReturnType<typeof useDisclosure>
  setSelectedNoteId: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedVideoId: React.Dispatch<React.SetStateAction<string | undefined>>
}
export default function NotesBrowser({
  selectedDirectoriesNames,
  selectedDirectoriesIds,
  setSelectedDirectoriesNames,
  setSelectedDirectoriesIds,
  selectedDirectoriesPath,
  setSelectedDirectoriesPath,

  viewNotesDisclosure,
  viewVideoDisclosure,
  setSelectedNoteId,
  setSelectedVideoId,
}: Props) {
  const [parentCategory, setParentCategory] = useState<ISubQuestionCategory>()

  const navigate = useNavigate()
  const loadCategories = async () => {
    setParentCategory(undefined)
    const _parentCategory = await getCategoriesByPath(selectedDirectoriesIds)
    if (_parentCategory) {
      setParentCategory(_parentCategory)
    }
  }

  useEffect(() => {
    loadCategories()
  }, [selectedDirectoriesIds])

  return (
    <>
      <Breadcrumbs
        variant="solid"
        radius="full"
        onAction={(key) => {
          // cut everything off the array after key
          setParentCategory(undefined)
          setSelectedDirectoriesIds((prev) => {
            const newPath = [...prev]
            newPath.splice(parseInt(key.toString()))
            return newPath
          })
          setSelectedDirectoriesNames((prev) => {
            const newPath = [...prev]
            newPath.splice(parseInt(key.toString()))
            return newPath
          })
          setSelectedDirectoriesPath((prev) => {
            const newPath = [...prev]
            newPath.splice(parseInt(key.toString()))
            return newPath
          })
        }}
      >
        <BreadcrumbItem>
          <FontAwesomeIcon key={0} icon={faHomeAlt} />
          All Categories
        </BreadcrumbItem>
        {selectedDirectoriesNames &&
          selectedDirectoriesNames.map((category, index) => (
            <BreadcrumbItem key={index + 1}>{category}</BreadcrumbItem>
          ))}
      </Breadcrumbs>

      <Spacer y={4} />

      {parentCategory && (
        <div className="notes-browser-grid">
          {parentCategory?.notes?.map((note, noteIndex) => (
            <LargeButtonWidget
              key={parentCategory._id}
              icon={faFile}
              title={note.title}
              variant="faded"
              color="primary"
              onClick={() => {
                navigate(`/Notes/${note._id}`)
              }}
            />
          ))}
          {parentCategory?.videos?.map((video, videoIndex) => (
            <LargeButtonWidget
              key={parentCategory._id}
              icon={faPlayCircle}
              title={video.title}
              variant="faded"
              color="primary"
              onClick={() => {
                navigate(`/Video/${video._id}`)
              }}
            />
          ))}
          {parentCategory?.subCategories.map((category, index) => (
            <LargeButtonWidget
              key={category._id}
              icon={faFolder}
              title={category.name}
              variant="faded"
              color="default"
              onClick={() => {
                setSelectedDirectoriesNames((prev) => [...prev, category.name])
                setSelectedDirectoriesIds((prev) => [...prev, category._id!])
                setSelectedDirectoriesPath((prev) => [
                  ...prev,
                  index.toString(),
                ])
              }}
            />
          ))}
        </div>
      )}
    </>
  )
}
