import { PageLoader } from "@/components/PageLoader"
import BaseModal from "@/core/components/Modals/BaseModal"
import { Card, Link, Spacer, UseDisclosureProps } from "@nextui-org/react"
import { useEffect, useState } from "react"
import { IQuestionCategoryVideo } from "@/api/types/question/question"
import { getVideoRoute } from "@/api/routes/admin/question-category/video"
import { Stream } from "@cloudflare/stream-react"
import PageHeader from "@/core/components/Page/PageHeader"
import LinksList from "@/core/components/LinksList"
import { useParams, useNavigate } from "react-router"
import Page from "@/core/components/Page"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import "./index.css"

enum VideoType {
  CLOUDFLARE = "CLOUDFLARE",
  YOUTUBE = "YOUTUBE",
  URL = "URL",
}

export default function VideoPage() {
  const { videoId } = useParams()
  const navigate = useNavigate()

  const [currentCategoryPath, setCurrentCategoryPath] = useState<string[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [video, setVideo] = useState<IQuestionCategoryVideo>()
  const [videoUrl, setVideoUrl] = useState<string>()
  const [videoType, setVideoType] = useState<VideoType>()

  useEffect(() => {
    // reload notes when open state changes
    ;(async () => {
      setVideo(undefined)
      setVideoUrl(undefined)
      setVideoType(undefined)

      if (!videoId) return

      setIsLoading(true)

      const video = await getVideoRoute(videoId)
      if (video) {
        setCurrentCategoryPath(video.path ?? [])

        let videoType

        if (video.url.startsWith("CLOUDFLARE_")) {
          setVideoUrl(video.url.replace("CLOUDFLARE_", ""))
          videoType = VideoType.CLOUDFLARE
        } else if (
          video.url.includes("youtube.com") ||
          video.url.includes("youtu.be")
        ) {
          setVideoUrl(video.url)
          videoType = VideoType.YOUTUBE
        } else {
          setVideoUrl(video.url)
          videoType = VideoType.URL
        }

        setVideoType(videoType)
        setVideo(video)

        if (videoType !== VideoType.CLOUDFLARE) {
          setIsLoading(false)
        }
      }
    })()
  }, [])

  return (
    <Page showSidebar={true}>
      {video && video.url && video.url !== "" && videoUrl ? (
        <>
          <PageHeader>
            <div className="flex flex-col gap-1">
              <div>
                <Link
                  className="link"
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} size="sm" />
                  <Spacer x={1} /> {"Back"}
                </Link>
                <h1>{video?.title}</h1>
              </div>
            </div>
          </PageHeader>
          <Spacer y={4} />
          <div className="video-modal-content">
            <Spacer y={2} />
            {isLoading && <PageLoader fullScreen={true} />}
            <div className="video-modal">
              <div className="video-container">
                {videoUrl ? (
                  <Card className="video-card">
                    {videoType === VideoType.CLOUDFLARE ? (
                      <Stream
                        responsive={true}
                        onLoadedData={() => {
                          setIsLoading(false)
                        }}
                        controls
                        src={videoUrl}
                      />
                    ) : videoType === VideoType.YOUTUBE ? (
                      <iframe
                        className="video-widget-video-embed"
                        style={{ height: 500 }}
                        src={videoUrl}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    ) : videoType === VideoType.URL ? (
                      <video
                        src={videoUrl}
                        controls
                        className="video-widget-video-embed"
                      />
                    ) : (
                      <p className="video-not-supported">
                        Still processing video, try again later (if this
                        persists, please contact our support).
                      </p>
                    )}
                  </Card>
                ) : (
                  "No video set..."
                )}
                <Spacer y={2} />
                <Card className="video-description-card">
                  <h2>Description</h2>
                  <p>{video?.description}</p>
                </Card>
                <Spacer y={2} />
                <LinksList title="Further Study" links={video?.links ?? []} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </Page>
  )
}
