import axios from "@/core/helpers/axios"
import { LoginEnum } from "@/api/types/auth/auth-enums"
import {
  CategoryAccessLevel,
  IQuestionCategoryLinks,
  IQuestionCategoryVideo,
  IQuestionCategoryVideoWithPath,
} from "@/api/types/question/question"

export async function getVideoRoute(
  videoId: string,
): Promise<IQuestionCategoryVideoWithPath | null | undefined> {
  try {
    const res = await axios.post("/question-category/videos/get/" + videoId)

    if (res.status === 200) {
      return res.data.video
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return null
    } else {
      return null
    }
  }
}

export async function getVideoRouteByPath(
  videoId: string,
  path: string[],
): Promise<IQuestionCategoryVideo | null | undefined> {
  try {
    const res = await axios.post("/question-category/videos/get/" + videoId, {
      path,
    })

    if (res.status === 200) {
      return res.data.video
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return null
    } else {
      return null
    }
  }
}

export async function addVideoRoute(
  title: string,
  description: string,
  url: string,
  links: IQuestionCategoryLinks[],
  accessLevel: CategoryAccessLevel,
  path: string[],
) {
  try {
    const res = await axios.post("/admin/question-category/videos/upload-url", {
      title,
      description,
      url,
      links,
      accessLevel,
      path,
    })

    if (res.status === 200) {
      return res.data.videoId
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function updateVideoRoute(
  _id: string,
  title: string,
  description: string,
  url: string,
  links: IQuestionCategoryLinks[],
  accessLevel: CategoryAccessLevel,
  path: string[],
) {
  try {
    const res = await axios.put("/admin/question-category/videos", {
      videoId: _id,
      title,
      description,
      url,
      links,
      accessLevel,
      path,
    })

    if (res.status === 200) {
      return true
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}

export async function deleteVideoRoute(videoId: string, path: string[]) {
  try {
    const res = await axios.post(
      "/admin/question-category/videos/delete/" + videoId,
      {
        path,
      },
    )

    if (res.status === 200) {
      return true
    }
  } catch (err: any) {
    console.error(err)
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status === 401
    ) {
      return LoginEnum.UNAUTHORIZED
    } else {
      return LoginEnum.OTHER
    }
  }
}
